import { computed, ref, watch } from "@vue/composition-api"

import { PRODUCT_TYPES, ORDERS_STATUS } from "@/utils/constant"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import appUtils from "@/utils/appUtils"
import i18n from "@/libs/i18n"
import store from "@/store"
import { useToast } from "vue-toastification/composition"

export default function useOrdersList() {
  // Use toast
  const toast = useToast()
  const showTable = ref(false)
  const refReportTable = ref(null)

  const userData = ref(appUtils.getLocalUser())
  const isProcessor = !!userData.value?.processors?.length

  // Table Handlers
  const tableColumns = computed(() => isProcessor
    ? [
      { key: "sku", label: "SKU", sortable: false, thClass: "text-nowrap" },
      { key: "name", label: i18n.t("reports.lbl_product"), sortable: false },
      { key: "quantity", label: i18n.t("lbl_quantity"), sortable: false },
    ]
    : [
      { key: "sku", label: "SKU", sortable: false, thClass: "text-nowrap" },
      { key: "order_code", label: i18n.t("reports.lbl_code"), sortable: false, thClass: "text-nowrap" },
      { key: "name", label: i18n.t("reports.lbl_product"), sortable: false },
      { key: "order_date", label: i18n.t("reports.lbl_start_date"), sortable: false },
      { key: "supplier_order_updated_at", label: i18n.t("reports.lbl_end_date"), sortable: false },
      { key: "owner_name", label: i18n.t("reports.lbl_owner_name"), sortable: false, },
      { key: "payment_method", label: i18n.t("reports.lbl_payment"), sortable: false },
      { key: "payment_status", label: i18n.t("reports.lbl_payment_status"), sortable: false },
      { key: "total_after_discount_price", label: i18n.t("reports.lbl_total_price"), sortable: false },
      { key: "discount_amount", label: i18n.t("reports.lbl_discount"), sortable: false, thClass: "text-center" },
      { key: "campaign_code", label: i18n.t("reports.lbl_campaign"), sortable: false },
      { key: "status", label: i18n.t("reports.lbl_status"), sortable: false, thClass: "text-center" },
      // {
      //   key: "price",
      //   label: `${i18n.t("lbl_price_per_unit")} (VNĐ)`,
      //   sortable: false,
      // },
      // { key: "quantity", label: i18n.t("lbl_quantity"), sortable: false },
      // { key: "total", label: `${i18n.t("lbl_total")} (VNĐ)` },
    ])

  const PRODUCT_OPTIONS = computed(() => [
    {
      label: i18n.t("lbl_all"),
      value: null,
    },
    {
      label: i18n.t(Object.keys(PRODUCT_TYPES)[0]),
      value: PRODUCT_TYPES.PRODUCT,
    },
    {
      label: i18n.t(Object.keys(PRODUCT_TYPES)[1]),
      value: PRODUCT_TYPES.SERVICE,
    },
  ])
  const ORDER_STATUS_OPTIONS = computed(() => [
    {
      label: i18n.t("lbl_all"),
      value: null,
    },
    {
      label: i18n.t("FINISHED"),
      value: ORDERS_STATUS.FINISHED,
    },
    {
      label: i18n.t("CANCELED"),
      value: ORDERS_STATUS.CANCELED,
    },
  ])
  const perPage = ref(10)
  const totals = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref("")
  const sortBy = ref("report_id")
  const isSortDirDesc = ref(true)
  const outOfStockFilter = ref(null)
  const activeFilter = ref(null)
  const timeout = ref(null)
  const allOpenRows = ref([])
  const dataList = ref([])
  const grandTotal = ref(0)
  const fromDate = ref(null)
  const toDate = ref(null)

  const indexSelectedType = ref(0)
  const selectedType = computed(() => PRODUCT_OPTIONS.value[indexSelectedType.value])

  const indexSelectedStatus = ref(0)
  const selectedStatus = computed(() => ORDER_STATUS_OPTIONS.value[indexSelectedStatus.value])

  const dataMeta = computed(() => {
    const localItemsCount = refReportTable.value
      ? refReportTable.value.localItems.length
      : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    }
  })

  watch(perPage, () => {
    currentPage.value = 1
  })

  watch([fromDate, toDate, selectedType, selectedStatus], () => {
    refetchData()
  })

  const refetchData = () => {
    console.log("run")
    refReportTable.value.refresh()
  }

  const fetchReportsList = (ctx, callback) => {
    console.log("run2")
    showTable.value = true
    const userData = appUtils.getLocalUser()
    const params = isProcessor ? {
      keyword: searchQuery.value,
      page_size: perPage.value,
      page_num: currentPage.value,
      processor_id: userData?.processorsInfo?.id,
      fromDate: fromDate.value,
      toDate: toDate.value
    } : {
      keyword: searchQuery.value,
      page_size: perPage.value,
      page_num: currentPage.value,
      supplier_id: userData?.supplierInfo?.id,
      fromdate: fromDate.value,
      todate: toDate.value,
      status: selectedStatus.value.value,
      type: selectedType.value.value
    }
    store
      .dispatch(`reports/${isProcessor ? "getProcessorReportsList" : "getReportsList"}`, params)
      .then((response) => {
        showTable.value = false
        const reports = response.result?.data?.data
        dataList.value = isProcessor
          ? response.result?.data?.data?.map((item) => {
            const { price, total_price, ...newItem } = item
            return {
              ...newItem,
            }
          })
          : response.result?.data?.data
        totals.value = response.result?.data?.meta?.total
        grandTotal.value = response.result?.data?.grant_total
        callback && callback(reports)
      })
      .catch((e) => {
        showTable.value = false
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("lbl_error_fetching_data"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
  }

  watch(
    [currentPage, perPage, searchQuery, outOfStockFilter, activeFilter],
    () => {
      showTable.value = true
      clearTimeout(timeout)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 2000)
    }
  )

  return {
    timeout,
    showTable,
    fetchReportsList,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReportTable,
    refetchData,
    allOpenRows,
    dataList,
    grandTotal,
    fromDate,
    toDate,
    selectedType,
    selectedStatus,
    PRODUCT_OPTIONS,
    ORDER_STATUS_OPTIONS,
    isProcessor,
    indexSelectedStatus,
    indexSelectedType
  }
}
